<template>
    <v-container>
      <v-text-field
        v-model="searchTerm"
        append-icon="mdi-magnify"
        label="Rechercher un produit"
        single-line
        hide-details
        @input="debouncedFetchProduits"
      ></v-text-field>
      <v-list>
        <v-list-item
          v-for="produit in produits"
          :key="produit.id"
        >
          <v-list-item-content>
            <v-list-item-title>{{ produit.Name }}</v-list-item-title>
            <v-list-item-subtitle>{{ produit.Rayon }} {{ produit.Emplacement }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </template>
  
  <script>
  import axios from '@/plugins/axios';
  
  export default {
    data() {
      return {
        searchTerm: '',
        produits: []
      };
    },
    methods: {
      fetchProduits() {
        axios.get(`/api/produits?search=${this.searchTerm}`)
          .then(response => {
            this.produits = response.data;
          })
          .catch(error => {
            console.error('Erreur lors de la récupération des produits:', error);
          });
      },
      debounce(func, wait) {
        let timeout;
        return function(...args) {
          const context = this;
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(context, args), wait);
        };
      }
    },
    created() {
      this.debouncedFetchProduits = this.debounce(this.fetchProduits, 1000);
    }
  }
  </script>
  